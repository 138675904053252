<template>
  <div>
    <CCard v-if="showContent">
            <CCardHeader>
                {{ title }}
            </CCardHeader>
            <CCardBody>
                <div class="info">
                    <p><b>Nombre: </b>{{ this.typeCategoriesFields.name }}</p>
                </div>
                <div class="button-container">
                    <CButton class="btn btn-secondary" style="margin-right:1em;" @click="back()">
                        Volver
                    </CButton>
                    <button v-if="!showForm && this.$store.state.isAdmin" class="btn btn-show-form" @click="showEditForm">
                        Editar tipo de categoría
                    </button>
                </div>
            </CCardBody>
        </CCard>
    <CCard v-if="showForm">
      <CCardHeader>
        {{ formTitle }}
      </CCardHeader>
      <CCardBody>
        <AbstractForm
          :formContent="formContent"
          @emit-methods="onEmitMethods"
          :fields="typeCategoriesFields"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
  
<script>
import { updateTypeCategory, getCategoryTypeData, getCategoriesTypeData } from "@/services/category_type";
import AbstractForm from "@/components/Forms/AbstractForm.vue";

export default {
  name: "updateTypeCategory",
  components: { AbstractForm },
  data() {
    return {
      id: null,
      categoriesType: [],
      title: 'Información del tipo de categoría',
      formTitle: "Editar tipo de categoría",
      showForm: false,
      showContent: true,
      typeCategoriesFields: {
        name: null,
      },
      formContent: {
        buttons: [
          {
            textButton: 'Cancelar',
            color: 'danger',
            event: 'cancel',
            style: 'margin: 10px',
            active: true
          },
          {
            textButton: "Aceptar",
            color: "success",
            event: "edit",
            style: "margin: 10px",
            active: this.$store.state.isAdmin,
          },
        ],
        rows: [
          {
            style: "",
            class: "",
            content: [
              {
                type: "element",
                rule: [],
                style: "",
                class: "",
                content: {
                  type: "input",
                  value: "",
                  key: "name",
                  label: "Nombre",
                  maxLength: 30,
                  specialCharactersAllowed: false,
                  allowSpaces: true,
                  error: ''
                },
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    this.id = this.$route.params.id;
    if(this.id){
      this.getTypeCategory();
      this.getCategoriesType();
    } 
  },
  methods: {
    onEmitMethods(event, dataTypeCategory) {
      switch (event) {
        case "edit": this.editTypeCategory(dataTypeCategory);break;
        case 'cancel': this.restoreContent(); break;
        case "back": this.back();break;
        default:break;
      }
    },
    showAlertError(msg) {
      this.$store.state.errorAlert = { status: true, msg: msg.toString() };
    },
    /**
     * Función en la que se obtienen los tipos de categoría
     *
     * @returns {object[]} Los tipos de categoría obtenidos.
     */
     getCategoriesType() {
       getCategoriesTypeData()
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString(),};
        })
        .then((result) => {
          this.categoriesType = result.filter(categoryType => categoryType.id !== Number(this.id));
        });
    },
    /**
    * Método para validar los inputs del formulario
    */
    checkInputs(dataTypeCategory) {
       const specialCharactersRegex = /^[a-zA-ZáéíóúÁÉÍÓÚ0-9\s]+$/;
       const validationRules = [
         { name: 'Nombre', field: 'name', message: 'Introduzca el nombre (máximo de 30 caracteres).', maxLength: 30 },
         { name: 'Nombre', field: 'name', message: 'El nombre no debe contener caracteres especiales.', regex: specialCharactersRegex }
      ];

      const existingCategoryType = this.categoriesType.find(categoryType => {
          return categoryType.name.toLowerCase() === dataTypeCategory.name.toLowerCase()
      });
            
      if (existingCategoryType) {
          this.showAlertError(`El nombre "${dataTypeCategory.name}" ya existe en los tipos de categoría.`);
          return false;
      }

      for (const rule of validationRules) {
         const value = dataTypeCategory[rule.field];
            if (value === undefined || value === null) {
                this.showAlertError(`El campo ${rule.name} está vacío`);
                return false;
            }
            if(rule.maxLength && value.length > rule.maxLength){
                this.showAlertError(`El número de caracteres de ${rule.name} debe ser menor o igual al siguiente valor: ${rule.maxLength}`);
                return false;
            }
            if (!value || (rule.maxLength && value.length > rule.maxLength) || (rule.minLength && value.length < rule.minLength) || (rule.noSpaces && value.includes(' ')) || (rule.value && value === rule.value) || (rule.minValue && parseInt(value) < rule.minValue) || (rule.equalField && value !== dataTypeCategory[rule.equalField]) || (rule.regex && !rule.regex.test(value))) {
              this.showAlertError(rule.message);
              return false;
            }
      }
       return true;
    },
    /**
    * Función para obtener los datos del tipo de categoría
    */
    getTypeCategory() {
        getCategoryTypeData(this.id)
        .catch((error) => { 
          if (error.response && error.response.status === 404){
            this.$router.push('/404');
            return
          }
          this.$store.state.errorAlert = { status: true, msg: error.toString() };
        })
        .then((result) => {
            this.typeCategoriesFields.name = result.name
        })
    },
    /**
     * Método para poder editar el tipo de categoría
     */
    editTypeCategory(dataTypeCategory) {
      if (this.checkInputs(dataTypeCategory) == false) return;
      updateTypeCategory(dataTypeCategory, this.id)
        .then(() => {
          this.$router.push("/type-categories");
        })
        .catch((error) => {
          this.$store.state.errorAlert = {status: true,msg: error.toString()};
        });
    },
    /**
     * Función para mostrar el alert durante 10 segundos.
     */
    showFailureParameters() {
      this.contAlertParams = 10;
    },
     /**
     * Función para mostrar el formulario para editar el tipo de categoría
      */
    showEditForm(){
      this.showForm = true;
    },
    /**
     * Función para restaurar el contenido
    */
    restoreContent(){
        this.showContent = true;
        this.showForm = false;
    },
    /**
     * Función para volver atrás
     */
    back() {
      this.$router.push("/type-categories");
    },
  },
};
</script>